<script>
import BundleGeneralSettings from '@app/components/BundleGeneralSettings.vue';
import BundleSEOSettings from '@app/components/BundleSEOSettings.vue';
import BundleTagsForm from '@app/components/BundleTagsForm.vue';
import BundleWebhooksForm from '@app/components/BundleWebhooksForm.vue';
import BundleAfterForm from '@app/components/BundleAfterForm.vue';

export default {
  components: {
    BundleAfterForm,
    BundleGeneralSettings,
    BundleSEOSettings,
    BundleTagsForm,
    BundleWebhooksForm,
  },
  head: {
    title: 'Paramètres',
  },
};
</script>

<template>
  <div>
    <AppToggleSection>
      <template #title>
        Général
      </template>
      <template #default>
        <BundleGeneralSettings />
      </template>
    </AppToggleSection>
    <AppToggleSection>
      <template #title>
        SEO (référencement)
      </template>
      <template #default>
        <BundleSEOSettings />
      </template>
    </AppToggleSection>
    <AppToggleSection>
    <template #title>
      Après achat/inscription
    </template>
    <template #default>
      <BundleAfterForm />
    </template>
  </AppToggleSection>
    <AppToggleSection>
      <template #title>
        <span style="display:flex;gap:8px;align-items: center;">
          Étiquettes <span class="tag is-primary ml-3">Nouveau</span>
        </span>
      </template>
      <template #default>
        <BundleTagsForm />
      </template>
    </AppToggleSection>

    <AppToggleSection>
      <template #title>
        Autorépondeur &amp; automatisation
      </template>
      <template #default>
        <BundleWebhooksForm />
      </template>
    </AppToggleSection>
  </div>
</template>
